<template>
  <div class="apps-index">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>应用中心</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="menu-list">
        <div class="item" @click="toExhibition">
          <img :src="require('../../assets/img/wszl.png')"/>
          <span class="title">网上展览</span>
        </div>
        <div class="item" @click="toShell">
          <img :src="require('../../assets/img/hyk.png')"/>
          <span class="title">会议壳</span>
        </div>
        <div class="item">
          <img :src="require('../../assets/img/qjxm.png')" @click="toPanorama"/>
          <span class="title">全景项目</span>
        </div>
        <div class="item">
          <img :src="require('../../assets/img/hys.png')"/>
          <span class="title">会议室</span>
        </div>
        <div class="item">
          <img :src="require('../../assets/img/zbj.png')"/>
          <span class="title">直播间</span>
        </div>
        <div class="item">
          <img :src="require('../../assets/img/kfzx.png')"/>
          <span class="title">客服中心</span>
        </div>
        <div class="item">
          <img :src="require('../../assets/img/gfwz.png')" @click="toPc"/>
          <span class="title">PC网站</span>
        </div>
        <div class="item">
          <img :src="require('../../assets/img/hxgl.png')"/>
          <span class="title">核销管理</span>
        </div>
        <div class="item">
          <img :src="require('../../assets/img/fwjl.png')"/>
          <span class="title">访问记录</span>
        </div>
        <div class="item">
          <img :src="require('../../assets/img/dyzj.png')"/>
          <span class="title">打印证件</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {}
  },
  methods: {
    // 全景项目
    toPanorama () {
      this.$router.push('/admin/apps/panorama/index')
    },
    // 会议壳
    toShell () {
      this.$router.push('/admin/apps/shell/index')
    },
    // PC
    toPc () {
      this.$router.push('/admin/apps/pc/index')
    },
    // 网上展览
    toExhibition () {
      this.$router.push('/admin/apps/exhibition/index')
    }
  }
}
</script>

<style scoped>
  .menu-list{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .menu-list .item {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 20px 0;
  }
  .menu-list .item:hover{
    cursor: pointer;
  }
  .menu-list .item img{
    width: 100%;
  }
  .menu-list .item span{
    color: #303133;
    font-size: 14px;
    font-weight: bold;
  }
  .menu-list .item:hover span{
    color: rgba(63, 157, 221, 1);
  }
</style>
